// import React, { useEffect, useState } from "react";

// function Roleta() {
//     const results = [];
//     return <div className="roleta">
//         <div className="row topbox">
//             <div className="col-md-6 col-md-offset-3 rollbox">
//                 <div className="line">{results}</div>
//             </div>
//         </div>
//     </div>;
// }

// export default Roleta;

//====================================================================================

// import { useState, useEffect } from 'react';
// import RoulettePro from 'react-roulette-pro';
// import 'react-roulette-pro/dist/index.css';


// const prizes = [];
// const winPrizeIndex = 0;
// const reproductionArray = (array = [], length = 0) => [
//   ...Array(length)
//     .fill('_')
//     .map(() => array[Math.floor(Math.random() * array.length)]),
// ];

// const reproducedPrizeList = [
//   ...prizes,
//   ...reproductionArray(prizes, prizes.length * 3),
//   ...prizes,
//   ...reproductionArray(prizes, prizes.length),
// ];

// const generateId = () =>
//   `${Date.now().toString(36)}-${Math.random().toString(36).substring(2)}`;

// const prizeList = reproducedPrizeList.map((prize) => ({
//   ...prize,
//   id: typeof crypto.randomUUID === 'function' ? crypto.randomUUID() : generateId(),
// }));

// const Roleta = () => {
//   const [start, setStart] = useState(false);
//     const prizeIndex = prizes.length * 4 + winPrizeIndex;

//     const handleStart = () => {
//         setStart((prevState) => !prevState);
//     };

//     const handlePrizeDefined = () => {
//         console.log('🥳 Prize defined! 🥳');
//     };

//     if(Boolean(agents) && !prizes.length) {
//         const [agents, setAgents] = useState();

//     useEffect(() => {
//         api
//         .get("/agents?language=pt-BR")
//         .then((response) => setAgents(response.data))
//         .catch((err) => {
//             console.error("Ops, ocorreu um erro! " + err);
//         });
//     }, []);


//         for (let i = 0; i < agents.data.length; i++) {
//             if(!agents.data[i].isPlayableCharacter) {
//                 continue;
//             }
//             prizes.push({
//                 image: agents.data[i].displayIcon
//             });
//         }
//         console.log(prizes);
//         const prizeIndex = prizes.length * 4 + winPrizeIndex;
//         const handleStart = () => {
//             setStart((prevState) => !prevState);
//         };

//         const handlePrizeDefined = () => {
//             console.log('🥳 Prize defined! 🥳');
//         };

//         return (
//             <>
//               <RoulettePro
//                 prizes={prizeList}
//                 prizeIndex={prizeIndex}
//                 start={start}
//                 onPrizeDefined={handlePrizeDefined}
//               />
//               <button onClick={handleStart}>Start</button>
//             </>
//           );
//     }
// };

// export default Roleta;

// import { useState } from 'react';

import RoulettePro from 'react-roulette-pro';
import 'react-roulette-pro/dist/index.css';
// import api from "../../services/api";


// const prizes = [
//   {
//     "id": "dade69b4-4f5a-8528-247b-219e5a1facd6",
//     "image": "https://media.valorant-api.com/agents/dade69b4-4f5a-8528-247b-219e5a1facd6/displayicon.png",
//     "text": "Fade"
//   },
//   {
//     "id": "5f8d3a7f-467b-97f3-062c-13acf203c006",
//     "image": "https://media.valorant-api.com/agents/5f8d3a7f-467b-97f3-062c-13acf203c006/displayicon.png",
//     "text": "Breach"
//   },
//   {
//     "id": "f94c3b30-42be-e959-889c-5aa313dba261",
//     "image": "https://media.valorant-api.com/agents/f94c3b30-42be-e959-889c-5aa313dba261/displayicon.png",
//     "text": "Raze"
//   },
//   {
//     "id": "22697a3d-45bf-8dd7-4fec-84a9e28c69d7",
//     "image": "https://media.valorant-api.com/agents/22697a3d-45bf-8dd7-4fec-84a9e28c69d7/displayicon.png",
//     "text": "Chamber"
//   },
//   {
//     "id": "601dbbe7-43ce-be57-2a40-4abd24953621",
//     "image": "https://media.valorant-api.com/agents/601dbbe7-43ce-be57-2a40-4abd24953621/displayicon.png",
//     "text": "KAY/O"
//   },
//   {
//     "id": "6f2a04ca-43e0-be17-7f36-b3908627744d",
//     "image": "https://media.valorant-api.com/agents/6f2a04ca-43e0-be17-7f36-b3908627744d/displayicon.png",
//     "text": "Skye"
//   },
//   {
//     "id": "117ed9e3-49f3-6512-3ccf-0cada7e3823b",
//     "image": "https://media.valorant-api.com/agents/117ed9e3-49f3-6512-3ccf-0cada7e3823b/displayicon.png",
//     "text": "Cypher"
//   },
//   {
//     "id": "320b2a48-4d9b-a075-30f1-1f93a9b638fa",
//     "image": "https://media.valorant-api.com/agents/320b2a48-4d9b-a075-30f1-1f93a9b638fa/displayicon.png",
//     "text": "Sova"
//   },
//   {
//     "id": "1e58de9c-4950-5125-93e9-a0aee9f98746",
//     "image": "https://media.valorant-api.com/agents/1e58de9c-4950-5125-93e9-a0aee9f98746/displayicon.png",
//     "text": "Killjoy"
//   },
//   {
//     "id": "95b78ed7-4637-86d9-7e41-71ba8c293152",
//     "image": "https://media.valorant-api.com/agents/95b78ed7-4637-86d9-7e41-71ba8c293152/displayicon.png",
//     "text": "Harbor"
//   },
//   {
//     "id": "707eab51-4836-f488-046a-cda6bf494859",
//     "image": "https://media.valorant-api.com/agents/707eab51-4836-f488-046a-cda6bf494859/displayicon.png",
//     "text": "Viper"
//   },
//   {
//     "id": "eb93336a-449b-9c1b-0a54-a891f7921d69",
//     "image": "https://media.valorant-api.com/agents/eb93336a-449b-9c1b-0a54-a891f7921d69/displayicon.png",
//     "text": "Phoenix"
//   },
//   {
//     "id": "41fb69c1-4189-7b37-f117-bcaf1e96f1bf",
//     "image": "https://media.valorant-api.com/agents/41fb69c1-4189-7b37-f117-bcaf1e96f1bf/displayicon.png",
//     "text": "Astra"
//   },
//   {
//     "id": "9f0d8ba9-4140-b941-57d3-a7ad57c6b417",
//     "image": "https://media.valorant-api.com/agents/9f0d8ba9-4140-b941-57d3-a7ad57c6b417/displayicon.png",
//     "text": "Brimstone"
//   },
//   {
//     "id": "bb2a4828-46eb-8cd1-e765-15848195d751",
//     "image": "https://media.valorant-api.com/agents/bb2a4828-46eb-8cd1-e765-15848195d751/displayicon.png",
//     "text": "Neon"
//   },
//   {
//     "id": "7f94d92c-4234-0a36-9646-3a87eb8b5c89",
//     "image": "https://media.valorant-api.com/agents/7f94d92c-4234-0a36-9646-3a87eb8b5c89/displayicon.png",
//     "text": "Yoru"
//   },
//   {
//     "id": "569fdd95-4d10-43ab-ca70-79becc718b46",
//     "image": "https://media.valorant-api.com/agents/569fdd95-4d10-43ab-ca70-79becc718b46/displayicon.png",
//     "text": "Sage"
//   },
//   {
//     "id": "a3bfb853-43b2-7238-a4f1-ad90e9e46bcc",
//     "image": "https://media.valorant-api.com/agents/a3bfb853-43b2-7238-a4f1-ad90e9e46bcc/displayicon.png",
//     "text": "Reyna"
//   },
//   {
//     "id": "8e253930-4c05-31dd-1b6c-968525494517",
//     "image": "https://media.valorant-api.com/agents/8e253930-4c05-31dd-1b6c-968525494517/displayicon.png",
//     "text": "Omen"
//   },
//   {
//     "id": "add6443a-41bd-e414-f6ad-e58d267f4e95",
//     "image": "https://media.valorant-api.com/agents/add6443a-41bd-e414-f6ad-e58d267f4e95/displayicon.png",
//     "text": "Jett"
//   }
// ];

// const winPrizeIndex = 0;

// const reproductionArray = (array = [], length = 0) => [
//   ...Array(length)
//     .fill('_')
//     .map(() => array[Math.floor(Math.random() * array.length)]),
// ];

// const reproducedPrizeList = [
//   ...prizes,
//   ...reproductionArray(prizes, prizes.length * 3),
//   ...prizes,
//   ...reproductionArray(prizes, prizes.length),
// ];

// const generateId = () =>
//   `${Date.now().toString(36)}-${Math.random().toString(36).substring(2)}`;

// const prizeList = reproducedPrizeList.map((prize) => ({
//   ...prize,
//   id: typeof crypto.randomUUID === 'function' ? crypto.randomUUID() : generateId(),
// }));

// const App = () => {

//   const prizeIndex = prizes.length * 4 + winPrizeIndex;

  

//   return (
//     <>
      
//     </>
//   );
// };

// export default App;



import React, { useEffect, useState } from "react"

function shuffleArray(array) {
  for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
  }

  return array;
}

function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}

const ErrorThen = () => {
  // const [agents, setAgents] = useState([])
  const [agentList, setAgentList] = useState([])

  const fetchData = () => {
    const generateId = () => `${Date.now().toString(36)}-${Math.random().toString(36).substring(2)}`;

    fetch("https://valorant-api.com/v1/agents?lang=pt-BR")
      .then(response => {
        if (response.ok) {
          return response.json();          
        } else {  
          throw new Error("Sorry something went wrong")
        }
      })
      .then(response => {
        let toReturn = [];
        let data = shuffleArray(response.data);
        data.forEach(agent => {
          if(!agent.isPlayableCharacter) {
            return;
          }

          toReturn.push({
            id: agent.uuid + '|' + generateId(),
            image: agent.displayIcon,
            text: agent.displayName
          });
        });

        return toReturn;  
      })
      .then(data => {
        data = shuffleArray(data);
        // setAgents(data)

        const reproducedPrizeList = [
          ...data,
          ...data,
          ...data,
          ...data,
          ...data,
          ...data,
          ...data,
          ...data,
          ...data,
          ...data,
          ...data,
          ...data,
          ...data
        ];

        setAgentList(reproducedPrizeList.map((agent) => ({
          ...agent,
          id: typeof crypto.randomUUID === 'function' ? crypto.randomUUID() : generateId(),
        })));
      })
      .catch(error => {
        console.error(error.message);
      })
  }

  useEffect(() => {
    fetchData()
  }, [])

  const [start, setStart] = useState(false);

  const handleStart = () => {
    setStart((prevState) => !prevState);
  };

  const handlePrizeDefined = (a) => {
    console.log('🥳 Prize defined! 🥳');
    console.log(a);
  };

  const prizeIndex = getRandomArbitrary((getRandomArbitrary(25, 75)), agentList.length - 10);

  return (
    <div>
      <RoulettePro
        prizes={agentList}
        start={start}
        onPrizeDefined={handlePrizeDefined}
        options={{ stopInCenter: true }}
        defaultDesignOptions={{ prizesWithText: true }}
        prizeIndex={prizeIndex}
        spinningTime={getRandomArbitrary(5,20)}
      />
      <div>
        <button className="d-none btn_start" onClick={handleStart}></button>
      </div>
    </div>
  )
}

export default ErrorThen