import './Components/Roleta';
import Roleta from './Components/Roleta';

function sorteio() {
  let elements = document.getElementsByClassName('btn_start')
  for (let index = 0; index < elements.length; index++) {
    const element = elements[index];
    element.click();
  }
}

function App() {
  return <div>
    <br></br>
    <div className='row'>
      <div className='col-6'>
        <Roleta></Roleta>
      </div>
      <div className='col-6'>
        <Roleta></Roleta>
      </div>
    </div>
    <br></br>
    <div className='row'>
      <div className='col-12'>
        <button className="btn btn-block" onClick={sorteio}>
          <span className="btn__inner">
            <span className="btn__slide"></span>
            <span className="btn__content">Sortear Jogadores</span>
          </span>
        </button>
      </div>
    </div>
  </div>;
}

export default App;